import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from 'moment';

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: this.$t('traitement.saisie_inventaire.historique_inventaire'),

            vuetable: {
                moreParams: {},
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center'
                    // },
                    {
                        name: 'date_inventaire_formatted',
                        title: this.$t('traitement.saisie_inventaire.date'),
                        sortField: 'date_inventaire',
                        dataClass: 'text-center'
                    },
                    {
                        name: 'nom_magasin',
                        title: this.$t('traitement.saisie_inventaire.magasin'),
                        sortField: 'nom_magasin',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'designation',
                        title: this.$t('traitement.saisie_inventaire.designation'),
                        dataClass: 'text-left'
                    },
                    {
                        name: 'nom_famille',
                        title: this.$t('traitement.saisie_inventaire.famille'),
                        dataClass: 'text-left'
                    },
                    {
                        name: 'stock_theorique',
                        title: 'Stock théorique',
                        sortField: this.$t('traitement.saisie_inventaire.stock_theorique'),
                        dataClass: 'text-right',
                        formatter(value) {
                            return new Intl.NumberFormat().format(value)
                        }
                    },
                    {
                        name: 'stock_physique',
                        title: this.$t('traitement.saisie_inventaire.stock_physique'),
                        sortField: 'stock_physique',
                        dataClass: 'text-right',
                        formatter(value) {
                            return new Intl.NumberFormat().format(value)
                        }
                    },
                    {
                        name: 'ecart_stock',
                        title: this.$t('traitement.saisie_inventaire.ecart'),
                        sortField: 'ecart_stock',
                        dataClass: 'text-right',
                        formatter(value) {
                            return new Intl.NumberFormat().format(value)
                        }
                    },
                    {
                        name: 'commentaire',
                        title: this.$t('traitement.saisie_inventaire.commentaire'),
                        sortField: 'commentaire',
                        dataClass: 'text-left',

                    },
                    {
                        name: 'date_heure_enregistrement',
                        title: this.$t('traitement.saisie_inventaire.date_enregistrement'),
                        sortField: 'date_heure_enregistrement',
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                    },
                    // {
                    //     name: 'actions',
                    //     width: "80px",
                    //     dataClass: "text-center"
                    // }

                ],
                sortOrder: [
                    { field: 'date_inventaire', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Date': 'date_inventaire_formatted',
                    'Magasin': 'nom_magasin',
                    'Designation': 'designation',
                    'Famille': 'nom_famille',
                    'Stock Théorique': 'stock_theorique',
                    'Ecart': 'ecart_stock',
                    'Stock Physique': 'stock_physique',
                    'Commentaire': 'commentaire',
                    'Date Heure Enregistrement': 'date_heure_enregistrement'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            motCle: "",
            crudform: {
                id: "",
                date_inventaire: "",
                magasin_id: "",
                inventaires: []
            },
            showDetail: true,
            crudmodaltitle: this.$t('traitement.saisie_inventaire.nouveau_inventaire'),
            listdata: {
                familles: [],
                articles: [],
                magasins: [],
                magasin_defaut: "",
            },
            criterearticle: {
                famille: "",
                search: ""
            },
            point_de_ventes: [],
            point_de_ventes_id: "",
            dateOptions: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            filtreDate: {
                dateDebut: "",
                dateFin: ""
            },


        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        editRow(rowData) {
            //alert("You clicked edit on" + JSON.stringify(rowData))
            axios.get(this.BASE_URL + "/inventaire/get/" + rowData.id).then(response => {
                //console.log(response.data[0]);
                this.crudform = response.data[0];
            });
            this.crudmodaltitle = "";
            this.openModal();
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm(this.$t('general.Confirm_text_supprimer'), {
                    title: this.$t('general.Confirm_header'),
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.$t('general.Confirm_button_supprimer'),
                    cancelTitle: this.$t('general.Confirm_button_cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/inventaire/delete/" + rowData.id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            this.vuetable.moreParams.point_de_ventes_id = this.point_de_ventes_id;
            this.vuetable.moreParams.startDate = this.filtreDate.dateDebut;
            this.vuetable.moreParams.endDate = this.filtreDate.dateFin;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        loadPointDeVente() {
            axios.get(this.BASE_URL + "/pointvente/getall/").then(response => {
                this.point_de_ventes = response.data;
            });
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("myCustomModal");
        },
        closeModal() {
            this.$bvModal.hide("myCustomModal");
        },
        addRow() {
            this.crudmodaltitle = "Nouvelle Inventaire";
            this.showDetail = true;
            this.crudform = {
                id: "",
                date_inventaire: moment().format('DD/MM/YYYY'),
                magasin_id: "",
                inventaires: []
            };
            if (this.listdata.magasin_defaut != '' && this.listdata.magasin_defaut != null) {
                this.crudform.magasin_id = this.listdata.magasin_defaut + '';
            }
            if (!(this.crudform.magasin_id > 0)) {
                if (this.listdata.magasins.length == 1) {
                    this.crudform.magasin_id = this.listdata.magasins[0].id;
                }
            }
            this.openModal();
        },
        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm(this.$t('general.Confirm_text_enregsitrer'), {
                    title: this.$t('general.Confirm_header'),
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: this.$t('general.Confirm_button_ok'),
                    cancelTitle: this.$t('general.Confirm_button_cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.BASE_URL + "/inventaire/addaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.closeModal();
                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/inventaire/fetchdata").then(response => {
                this.listdata.familles = response.data.familles;
                this.listdata.magasins = response.data.magasins;
                //this.listdata.familles.unshift({ id: "", nom_famille: "choisir une famille" });
                this.listdata.magasin_defaut = response.data.magasin_defaut;

                if (this.listdata.magasin_defaut != '' && this.listdata.magasin_defaut != null) {
                    this.crudform.magasin_id = this.listdata.magasin_defaut + '';
                }
            });
        },
        setCritereArticleFilter: function() {
            axios.get(this.BASE_URL + "/articles/fetchlimitted", {
                params: {
                    famille: this.criterearticle.famille,
                    search: this.criterearticle.search,
                    magasin: this.crudform.magasin_id
                }
            }).then(response => {
                this.listdata.articles = response.data;
            });
        },
        resetCritereArticleFilter: function() {
            this.criterearticle.famille = "";
            this.criterearticle.search = "";

            this.setCritereArticleFilter();
        },
        pushrow: function(articleData) {
            console.log(articleData)
            var exist = false;
            for (var i = 0; i < this.crudform.inventaires.length; i++) {
                var item = this.crudform.inventaires[i];
                if (item.articles_id == articleData.articles_id) {
                    Vue.$toast.open({
                        message: "Cet article a déjà été choisit",
                        type: 'warning',
                        position: 'top-right'
                    });
                    exist = true;
                    break;
                }
            }


            if (!exist) {
                this.crudform.inventaires.push({
                    articles_id: articleData.articles_id,
                    ref_article: articleData.ref_article,
                    designation: articleData.designation,
                    stock_theorique: articleData.stock_reel,
                    stock_physique: "",
                    ecart_stock: "",
                    commentaire: ""
                });
            }

        },
        delRow: function(i) {
            this.crudform.inventaires.splice(i, 1);
        },
        updateEcart: function(i) {
            console.log("Update");

            var item = this.crudform.inventaires[i];
            if (item.stock_physique == '' || item.stock_physique == null) {
                item.stock_physique = 0;
            }
            if (item.stock_theorique == '' || item.stock_theorique == null) {
                item.stock_theorique = 0;
            }
            console.log(item);
            this.crudform.inventaires[i].ecart_stock = parseFloat(item.stock_physique) - parseFloat(item.stock_theorique);
            console.log(this.crudform.inventaires[i].ecart_stock);


        }

    },
    computed: {
        articlesList: function() {
            var that = this;
            return this.listdata.articles.filter(function(article) {
                if (article.ref_article.toLowerCase().includes(that.criterearticle.search.toLowerCase()) || article.designation.toLowerCase().includes(that.criterearticle.search.toLowerCase())) {
                    return true;
                }
                return false;
            });
        }
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        this.motCle = "";
        this.criteriacol = "";


        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;


        this.loadPointDeVente();
        this.fetchData();
        //this.setCritereArticleFilter();
    },
    watch: {
        'criterearticle.famille': function() {
            this.setCritereArticleFilter();
        },
        'crudform.magasin_id': function() {
            this.setCritereArticleFilter();
        },


    },
    mounted: function() {
        // $("input").on("keydown", function(e) {
        //     e.preventDefault();
        //     return false;
        // });
        if (this.$route.params.type == 'add') {
            this.addRow();
        }
    }
}